<template>
  <b-nav-item to="/inbox">
    <feather-icon
      :badge="unreadMessages"
      badge-classes="badge-danger"
      size="21"
      :icon="`MessageSquareIcon`"
    />
  </b-nav-item>
</template>
<script>
import { BNavItem } from "bootstrap-vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  components: {
    BNavItem,
  },
  computed: {
    ...mapGetters({
      unreadMessages: "auth/getUnreadMessagesCount",
    }),
  },
};
</script>
